
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { RawOrder } from '@/interfaces';

  const ordersStore = namespace('orders');

  @Component
  export default class OrderRaw extends Vue {
    data: RawOrder[] = [];

    get id(): string | null {
      return (this.$route.params && this.$route.params.id).toString() || null;
    }
    
    async mounted(): Promise<void> {
      if (!this.id) {
        return;
      }

      this.data = await this.getRawData(this.id);
    }

    @ordersStore.Action
    getRawData!: (id: string) => Promise<RawOrder[]>;
  }
